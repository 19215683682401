import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ChefHat
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNiAxMy44N0E0IDQgMCAwIDEgNy40MSA2YTUuMTEgNS4xMSAwIDAgMSAxLjA1LTEuNTQgNSA1IDAgMCAxIDcuMDggMEE1LjExIDUuMTEgMCAwIDEgMTYuNTkgNiA0IDQgMCAwIDEgMTggMTMuODdWMjFINloiIC8+CiAgPGxpbmUgeDE9IjYiIHgyPSIxOCIgeTE9IjE3IiB5Mj0iMTciIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/chef-hat
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const ChefHat = createLucideIcon('ChefHat', [
  [
    'path',
    {
      d: 'M6 13.87A4 4 0 0 1 7.41 6a5.11 5.11 0 0 1 1.05-1.54 5 5 0 0 1 7.08 0A5.11 5.11 0 0 1 16.59 6 4 4 0 0 1 18 13.87V21H6Z',
      key: 'z3ra2g',
    },
  ],
  ['line', { x1: '6', x2: '18', y1: '17', y2: '17', key: '12q60k' }],
]);

export default ChefHat;
